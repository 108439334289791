import { BehaviorSubject } from "rxjs";
let toast = new BehaviorSubject({ type: "", desc: "", show: false }),
  popup = new BehaviorSubject({ show: false, content: null, title: "" });
export const PlayFab = "PlayFab";
export const Sphereone = "sphereoneSdk";
export const Ethereum = "Ethereum";
export const ImmutableX = "ImmutableX";
export const ImmutablePassport = "ImmutablePassport";
// const host = window.location.host;
export const environment = "prod"
// host === "dev.qorbaby.com" ||
//   host === "127.0.0.1:8887" ||
//   host === "localhost:3000"
//   ? "dev"
//   : "prod";
export const linkAddress =
  environment === "dev"
    ? "https://link.sandbox.x.immutable.com"
    : "https://link.x.immutable.com";
export const apiAddress =
  environment === "dev"
    ? "https://api.sandbox.x.immutable.com/v1"
    : "https://api.x.immutable.com/v1";

export const Toast = {
  set: (value) => toast.next(value),
  get: () => toast.asObservable(),
};
export const PopUp = {
  set: (value) => popup.next(value),
  get: () => popup.asObservable(),
};

export const marketItems = [
  {
    id: 0,
    name: "Qor-X Passes",
    desc: "The Qor-X Pass is the Qorbi World equivalent of the well-known Battle Pass system most FPS games utilize. With this pass in your wallet, you get access to a rewarding system that allows you to unlock rewards from staking, airdrops, achievements, and in-game content across the entire Qorbi World gaming ecosystem.",
    price: 25,
    url: "https://buy.stripe.com/28o5ntbTRdCUgMg8wy?client_reference_id=",
    moreUrl: "",
    coinbase: "https://commerce.coinbase.com/checkout/a1a7bece-3c18-4c6c-a757-9105b0977987",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/qorx/Qor_X_v2.jpg",
    source: "Market"
  },
  {
    id: 1,
    name: "Qor Bundle",
    desc: "Dive into the excitement with our exclusive Bundle. This bundle offers a thrilling opportunity to obtain a variety of random skins, each designed to enhance your in-game experience or, trade them to get something better.",
    price: 3,
    url: "https://buy.stripe.com/00g9DJ2jhcyQeE828w?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/c2ba3444-307c-480f-926d-e3e6aeb0841d",
    moreUrl: "https://qorbiworld.com/bundles",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Qor.jpg",
    source: "Market"
  },
  {
    id: 2,
    name: "One Tap Loot Box ",
    desc: "Each One Tap Loot box has a mystery item inside. Each item is an in-game skin used to add more fun to your gameplay.",
    price: 5,
    url: "https://buy.stripe.com/aEU3fl8HFaqIgMgcN9?client_reference_id=",
    moreUrl: "https://playonetap.com/lootbox",
    coinbase: "https://commerce.coinbase.com/checkout/cd6859bc-9b28-4c00-bcbe-e3dfbaf59d20",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png",
    source: "Market"
  },

  {
    id: 3,
    name: "Dog (Multiplier x4)",
    desc: "The One Tap Dog is a great friend to take into a new round. The dog gives you the ability to put up a turret.",
    price: 55,
    url: "https://buy.stripe.com/dR617d4rp8iA7bGeUV?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/c5ae786b-05f3-43c9-aa5e-9252d24e0051",
    moreUrl: "https://playonetap.com/pets",
    img: "https://qbaby-web-assets.s3.amazonaws.com/dog.png",
    source: "Market"
  },
  {
    id: 4,
    name: "Armadillo (Multiplier x3)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Armadillo's special ability allows it to use its shell to create a whirlpool, dealing damage to your enemy.",
    price: 65,
    url: "https://buy.stripe.com/eVabLR4rp56o9jO6or?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/a8bab475-ea04-41f7-809f-bfca7c8f92ee",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Armadillo.png",
    source: "Market"
  },
  {
    id: 5,
    name: "Dragon (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Dragon's special ability allows it to create a sphere of light that protects you from damage within it.",
    price: 55,
    url: "https://buy.stripe.com/cN217dbTR6as67C4gk?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/aff3c283-db35-4ea2-babf-e014926ccc3d",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Dragon.png",
    source: "Market"
  },
  {
    id: 6,
    name: "Pterodactyl (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Pterodactyl's special ability allows it to shield you with its wings, thus protecting you from damage caused by your enemies.",
    price: 55,
    url: "https://buy.stripe.com/00geY3f637ewfIc9AF?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/9e147409-1c37-497c-b071-4a70a669651f",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Pteranodon.png",
    source: "Market"
  },
  {
    id: 7,
    name: "Sabertooth (Multiplier x3)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Sabertooth's special ability allows it to ram into your opponent, causing damage.",
    price: "55",
    url: "https://buy.stripe.com/28ocPV1fdbuM8fK006?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/1504cc84-be95-4971-a454-6bbb92ae1826",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Sabertooth.png",
    source: "Market"
  },
  {
    id: 8,
    name: "Snake (Multiplier x4)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Snake's special ability coats your bullets in snake venom, causing an additional 2 seconds of damage per bullet impact to your opponent.",
    price: 75,
    url: "https://buy.stripe.com/bIY4jp8HFbuM7bGcMT?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/07672550-97b3-4dc5-b2ae-af9840ab2e08",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/snake.png",
    source: "Market"
  },
  {
    id: 9,
    name: "Monkey (Multiplier x4)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Monkey's special ability allows it to Kung Fu kick your enemies at close range, causing damage.",
    price: 65,
    url: "https://buy.stripe.com/5kA5nt2jh7eweE84go?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/8fd4501b-7970-4e4c-98d3-eb88cebb37c6",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Monkey.png",
    source: "Market"
  },
  {
    id: 10,
    name: "Fox (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Fox's special ability allows it to shock your enemy with an electrical charge, causing damage.",
    price: 38,
    url: "https://buy.stripe.com/eVa8zFe1Z9mEdA47sB?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/aa37aeb8-8663-4545-99d4-6d1d724d1d1f",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Fox.png",
    source: "Market"
  },
  {
    id: 11,
    name: "Rabbit (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Rabbit's special ability allows it to jump into the air. Upon landing, it causes damage to nearby enemies within a 2-meter radius.",
    price: 45,
    url: "https://buy.stripe.com/5kA9DJ2jh7ew53y5ku?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/c8cede06-0e3a-4dc9-a240-9a9d6c09091f",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Rabbit.png",
    source: "Market"
  },
  {
    id: 12,
    name: "Wolf (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Wolf's special ability allows it to bark a sharp pitch, stunning your opponent.",
    price: 45,
    url: "https://buy.stripe.com/bIY7vBf632YganSaEP?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/46472e96-a019-41a2-aed5-1c0ad463a8e4",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Wolf.png",
    source: "Market"
  },
  {
    id: 13,
    name: "Lion (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Lion's special ability allows it to give a mighty roar, exhaling a path of flames that causes fire damage to your enemy.",
    price: 55,
    url: "https://buy.stripe.com/eVa6rx6zx8iA53y8wI?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/37c124ed-9223-4f80-8926-723c0103ddb2",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Lion.png",
    source: "Market"
  },
  {
    id: 14,
    name: "T-Rex (Multiplier x3)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The T-Rex's special ability allows it to unleash its terrifying roar, stopping enemies in their path and causing a temporary stun.",
    price: 65,
    url: "https://buy.stripe.com/6oE4jp1fd0Q8fIccMZ?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/ea098aac-62b2-4c65-b852-8d9dad7dd8a9",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/trex.png",
    source: "Market"
  },
  {
    id: 15,
    name: "Cat (Multiplier x3)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Cat's special ability gives you stealth, quieting your movement and giving you 150% movement speed.",
    price: 50,
    url: "https://buy.stripe.com/cN2dTZ5vt6asdA46oC?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/cab99b16-3f49-441f-8955-c7516e71cf6a",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Cat.png",
    source: "Market"
  },
  {
    id: 16,
    name: "Eagle (Multiplier x5)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Eagle's special ability utilizes its eagle-eye vision, revealing enemy positions.",
    price: 70,
    url: "https://buy.stripe.com/4gw8zF7DB1Uc53y7sH?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/da773b1f-1c88-490c-8a69-0acbcfb4daf2",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Eagle.png",
    source: "Market"
  },
  {
    id: 17,
    name: "Mouse (Multiplier x8)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Mouse's special ability emits a blinding flash, causing temporary disorientation of nearby enemies, leaving them vulnerable to attacks and evasions.",
    price: 75,
    url: "https://buy.stripe.com/7sI3fl4rp6as2VqeVa?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/0b273348-dd0d-46b9-a276-f9a7effd29a5",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Mouse.png",
    source: "Market"
  },
  {
    id: 18,
    name: "Panther (Multiplier x4)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Panther's special ability allows it to move stealthily, allowing you to spot nearby enemies.",
    price: 65,
    url: "https://buy.stripe.com/3csbLRaPNcyQeE8eVb?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/c69c36dc-c1b0-4f96-b463-5dc1d71e44d3",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Panter.png",
    source: "Market"
  },
  {
    id: 19,
    name: "Hyena (Multiplier x5)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Hyena's special ability allows you to amplify your health when you pick up a health pack.",
    price: 70,
    url: "https://buy.stripe.com/14k17d6zx1Uc3Zu14m?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/7ee3f798-0a96-4547-9e78-b2d50d8325d5",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/Hyena.png",
    source: "Market"
  },
  {
    id: 20,
    name: "Tiger (Multiplier x3)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Tiger's special ability gives it a mighty roar, healing you for 40 health. In team game modes, its roar also heals nearby allies.",
    price: 50,
    url: "https://buy.stripe.com/3cs5nt4rp9mEfIcaEX?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/535620b6-b7b5-4ed1-95ac-5d0ad4044748",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/tiger.png",
    source: "Market"
  },
  {
    id: 21,
    name: "Toad (Multiplier x2)",
    desc: "This item is a Qorbi World pet that is utilized in each of the Qorbi World games. One pet is viable for use across all available Qorbi World games. OneTap - The Toad's special ability allows it to flick its tongue out, throwing poison at the enemy.",
    price: "38",
    url: "https://buy.stripe.com/4gwbLR2jh9mE0NifZi?client_reference_id=",
    coinbase: "https://commerce.coinbase.com/checkout/017de497-151e-4ec6-8858-07b9986caa8c",
    moreUrl: "https://playonetap.com/pets",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/eggs/toad.png",
    source: "Market"
  },
  // Private Market
  {
    id: 22,
    name: "Qorb Badge",
    desc: "The Qorbs Collection is finally here. The official play-to-earn collection for the Qorbi World games. You will be purchasing a one of a kind minting badge, you will use this budge on the Qorbiworld.com website to mint",
    price: 150,
    url: "https://buy.stripe.com/00g3flaPN0Q81Rm9AW?client_reference_id=",
    // url: "https://qorbiworld.com/market",
    coinbase: "https://commerce.coinbase.com/checkout/c35e3fde-dfbf-4d11-98a3-998cce6633d6",
    moreUrl: "",
    img: "https://atomichub-ipfs.com/ipfs/QmbueMqSRpzX7v8NKZqsvza6s5mREe7LYozFSx7T4sUUkK",
    source: "Private Market"
  },
]

export const bundlesArray = [
  {
    id: 1,
    name: "Ally Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Ally.jpg",
    carousel: {
      'Clint-44 Ally': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Ally.png',
      'Eva-R46 Ally': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Ally.png',
      'Hagal Ally': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Ally.png',
      'QuickHammer Ally': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Ally.png',
      'Ra-X45 Ally': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Ally.png',
    }
  },
  {
    id: 2,
    name: "Altru Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Altru.jpg",
    carousel: {
      'Clint-44 Altru': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Altru.png',
      'Eva-R46 Altru': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Altru.png',
      'Hagal Altru': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Altru.png',
      'QuickHammer Altru': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Altru.png',
      'Ra-X45 Altru': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Altru.png',
    }
  },
  {
    id: 3,
    name: "Atomic Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Atomic.jpg",
    carousel: {
      'Clint-44 Atomic': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Atomic.png',
      'Eva-R46 Atomic': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Atomic.png',
      'Hagal Atomic': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Atomic.png',
      'QuickHammer Atomic': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Atomic.png',
      'Ra-X45 Atomic': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Atomic.png',
    }
  },
  {
    id: 4,
    name: "Forge Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Forge.jpg",
    carousel: {
      'Clint-44 Forge': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Forge.png',
      'Eva-R46 Forge': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Forge.png',
      'Hagal Forge': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Forge.png',
      'QuickHammer Forge': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Forge.png',
      'Ra-X45 Forge': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Forge.png',
    }
  },
  {
    id: 5,
    name: "Hyper Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Hyper.jpg",
    carousel: {
      'Clint-44 Hyper': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Hyper.png',
      'Eva-R46 Hyper': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Hyper.png',
      'Hagal Hyper': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Hyper.png',
      'QuickHammer Hyper': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Hyper.png',
      'Ra-X45 Hyper': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Hyper.png',
    }
  },
  {
    id: 6,
    name: "Mon Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Mon.jpg",
    carousel: {
      'Clint-44 Mon': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Mon.png',
      'Eva-R46 Mon': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Mon.png',
      'Hagal Mon': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Mon.png',
      'QuickHammer Mon': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Mon.png',
      'Ra-X45 Mon': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Mon.png',
    }
  },
  {
    id: 7,
    name: "One Tap Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+OneTap.jpg",
    carousel: {
      'Clint-44 One Tap': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_OneTap.png',
      'Eva-R46 One Tap': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_OneTap.png',
      'Hagal One Tap': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_OneTap.png',
      'QuickHammer One Tap': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_OneTap.png',
      'Ra-X45 One Tap': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_OneTap.png',
    }
  },
  {
    id: 8,
    name: "Qor Gaming Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+QorGaming.jpg",
    source: "Bundle",
    carousel: {
      'Clint-44 Qor Gaming': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_QorGaming.png',
      'Eva-R46 Qor Gaming': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_QorGaming.png',
      'Hagal Qor Gaming': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_QorGaming.png',
      'QuickHammer Qor Gaming': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_QorGaming.png',
      'Ra-X45 Qor Gaming': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_QorGaming.png',
    }
  },
  {
    id: 9,
    name: "Qorbi World Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+QorbiWorld.jpg",
    source: "Bundle",
    carousel: {
      'Clint-44 Qorbi World': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_QorbiWorld.png',
      'Eva-R46 Qorbi World': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_QorbiWorld.png',
      'Hagal Qorbi World': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_QorbiWorld.png',
      'QuickHammer Qorbi World': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_QorbiWorld.png',
      'Ra-X45 Qorbi World': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_QorbiWorld.png',
    }
  },
  {
    id: 10,
    name: "Xis Bundle",
    img: "https://d2ceeuvjz02d1t.cloudfront.net/Bundle+Skins+-+Xis.jpg",
    source: "Bundle",
    carousel: {
      'Clint-44 Xis': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Clint-44_Xis.png',
      'Eva-R46 Xis': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Eva_Xis.png',
      'Hagal Xis': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Hagal_Xis.png',
      'QuickHammer Xis': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/QuickHammer_Xis.png',
      'Ra-X45 Xis': 'https://qorbaby-nft-files.s3.amazonaws.com/Skins/Ra-X45_Xis.png',
    }
  },
]