import React, { useContext, useState, useEffect } from "react";
import IMXLogo from "../assets/svg/ImmutableLogo";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WombatLogo from "../assets/img/wombat-logo-login.png";
import PassportLogo from "../assets/img/passport-logo.png";
import axios from "axios";
import { Ethereum, ImmutableX, ImmutablePassport, Toast } from "../utils/Global";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import { Context } from "../context/WalletContext";
import { sphereoneSdk } from "../utils/SphereOne";
import LoaderGlass from "../assets/svg/LoaderGlass";
import { passportInstance } from "../context/PassportService";

const qorbiServices = require('../context/QorbiService');

const UserProfile = () => {
    const storedItem = localStorage.getItem('unauthorizedPath');
    const { immutableLogin, LoginWallet, metamaskLogin, localLogin, state } = useContext(Context);
    const { setRegisterWallet, setRegisterUser, setQorbiUserData, qorbiUserData } = useSphereOneProvider();
    const [loading, setLoading] = useState(true);

    const isLoggedIn = state.user !== null;

    useEffect(() => {
        if (qorbiUserData) {
            setLoading(false);
        }
    }, [qorbiUserData]);

    const isIMX = async (address) => {
        try {
            const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
            return !!immutableResponse.data;
        } catch (immutableError) {
            return false;
        }
    };

    const validateWallet = async (address) => {
        const isImx = await isIMX(address);
        if (isImx) {
            try {
                const userResponse = await qorbiServices.getQorbiUser(address);
                setQorbiUserData(userResponse);
            } catch (sphereOneError) {
                setRegisterUser(true);
            }
        } else {
            setRegisterWallet({ show: true, wallet: address });
        }
    };

    const connect_Immutable = async () => {
        try {
            if ("ethereum" in window) {
                const userAddress = await immutableLogin();
                if (userAddress.data) {
                    const userInfo = {
                        authType: ImmutableX,
                        data: userAddress.data,
                        isWombat: undefined
                    };
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    validateWallet(userInfo.data);
                    localLogin(userInfo);
                    if (storedItem) {
                        localStorage.removeItem('unauthorizedPath');
                        window.location.href = storedItem;
                    }
                }
            } else {
                LoginWallet();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const connect_Passport = async () => {
        try {
            setLoading(true)
            const provider = passportInstance.connectEvm();
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            const userProfile = await passportInstance.getUserInfo();
            const userInfo = {
                authType: ImmutablePassport,
                data: accounts[0],
                isWombat: undefined
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            try {
                const userResponse = await qorbiServices.getQorbiUser(userProfile.email);
                setQorbiUserData(userResponse);
            } catch (sphereOneError) {
                setRegisterUser(true);
            }
            localLogin(userInfo);
            setLoading(false)
            if (storedItem) {
                localStorage.removeItem('unauthorizedPath');
                window.location.href = storedItem;
            }
        } catch (error) {
            console.error(error);
        }
        window.location.reload();
    };

    const connect_eth_provider = async () => {
        try {
            if ("ethereum" in window) {
                const userAddress = await metamaskLogin();
                if (userAddress.data) {
                    const userInfo = {
                        authType: Ethereum,
                        data: userAddress.data,
                        isWombat: window.ethereum.isWombat
                    };
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    validateWallet(userInfo.data);
                    localLogin(userInfo);
                    if (storedItem) {
                        localStorage.removeItem('unauthorizedPath');
                        window.location.href = storedItem;
                    }
                }
            } else {
                Toast.set({
                    show: true,
                    desc: (
                        <p>
                            Metamask is not installed. <br />
                            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                                Metamask
                            </a>
                        </p>
                    ),
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const login_SphereOne = async () => {
        try {
            await sphereoneSdk.login();
        } catch (e) {
            console.error(e);
        }
    };

    if (loading || !isLoggedIn) {
        return (
            <div className="loading-magnifying-glass">
                <LoaderGlass></LoaderGlass>
                <h1 className="banner-title">Getting Information from the librarian!!!</h1>
                <h2 className="banner-title">Yes, we have a librarian...</h2>
                <div className="signin-overlay">
                    <div className="signin-box">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h1>SIGN IN</h1>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="welcome">
                                <p style={{ marginRight: 3 }}>Welcome to</p>
                                <p style={{ color: "green" }}>Qorbi World</p>
                            </div>
                        </div>
                        <div className="signin-buttons">
                            <button onClick={connect_Passport} style={{ backgroundColor: "#14b3cb" }}>
                                <img src={PassportLogo} alt="Passport Logo" width={25} height={25} /> Login with Passport
                            </button>
                        </div>
                        <p className="welcome">Or sign in with your wallet</p>
                        <div className="signin-buttons">
                            <button onClick={connect_eth_provider} style={{ backgroundColor: "#eb8022" }}>
                                <MetamaskLogo /> Connect with Metamask
                            </button>
                            <button onClick={connect_eth_provider} style={{ backgroundColor: "#FF4F24" }}>
                                <img src={WombatLogo} alt="Wombat Logo" width={25} height={25} /> Connect with Wombat
                            </button>
                            <button onClick={connect_Immutable} style={{ backgroundColor: "#14b3cb" }}>
                                <IMXLogo /> Connect with ImmutableX
                            </button>

                        </div>
                        <p className="welcome">Or enter using</p>
                        <div className="signin-buttons">
                            <button onClick={login_SphereOne} style={{ color: "black" }}>
                                Connect with SphereOne
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="profile-container">
            <h1 className="profile-green-title profile-text-center">
                {qorbiUserData.username}
            </h1>
            <div className="profile-info-container">
                <div className="total-qorbi profile-info-item wallet-container" style={{ cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(qorbiUserData?.walletId)}>
                    Wallet:
                    <div className="profile-info-text">
                        {qorbiUserData?.walletId}
                    </div>
                    <div className="profile-copy-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M13 1H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm1 12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10z" />
                            <path d="M5 5h6v2H5V5zm0 4h6v2H5V9z" />
                        </svg>
                    </div>
                </div>
                <div className="total-qorbi profile-info-item wallet-container" style={{ cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(qorbiUserData?.referalCode)}>
                    Referral Code:
                    <div className="profile-info-text">
                        {qorbiUserData?.referalCode || "Generating"}
                    </div>
                    <div className="profile-copy-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M13 1H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm1 12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v10z" />
                            <path d="M5 5h6v2H5V5zm0 4h6v2H5V9z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="profile-info-container">
                <div className="qorbi-earnings">
                    {qorbiUserData?.specials && (
                        <div className="profile-specials">
                            <h2 style={{ textAlign: "center" }}>Tournaments Participation</h2>
                            <table className="profile-leaderboard-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Leaderboards</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {qorbiUserData.specials.map((special, index) => (
                                        special !== "" ?
                                            (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{special}</td>
                                                </tr>
                                            ) : (
                                                <tr key={index}>
                                                    <td>{index}</td>
                                                    <td>None Yet</td>
                                                </tr>
                                            )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                <div className="total-qorbi profile-info-item">
                    Email:
                    <div className="profile-info-text">
                        {qorbiUserData?.email}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
